import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ClientOnly from "../components/clientOnly"
import { Container, Row, Col } from "react-bootstrap"
import { AiFillTwitterCircle } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import { FaFacebook } from "react-icons/fa"
import { BsArrowClockwise } from "react-icons/bs"
import { Link } from "gatsby"

const ThankYouPage = ({ data, location }) => {
  return (
    <div>
      <SEO title="Thank you!" />
      <ClientOnly>
        <Layout location={location}>
          <div className="section">
            <div>
              <Container
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                  flexDirection: "column",
                }}
              >
                <Row style={{ flexDirection: "row" }}>
                  <h1 style={{ textAlign: "center" }}>
                    Thank you for sharing their story.
                  </h1>
                  <Col xs="12" lg={{ span: "8", offset: "2" }}>
                    <p>
                      We are bringing together healthcare workers, patients,
                      families, and Californians from across the state with
                      virtual and physical memorials remembering those we have
                      lost to COVID-19. Together, we honor their legacies.
                    </p>
                    <p>
                      Use the links below to invite family and friends to join
                      us during the National COVID Memorial Month.
                    </p>
                  </Col>
                </Row>
                <p>
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fforeveressential.org%2Fremembrance%2F#4"
                    target="__blank"
                  >
                    <FaFacebook size="30px" />
                  </a>
                  <a
                    href="https://ctt.ec/22d64"
                    target="__blank"
                  >
                    <AiFillTwitterCircle
                      size="33px"
                      style={{ marginLeft: "15px" }}
                    />
                  </a>
                  {/* <a
                    href="https://www.instagram.com/frveressential/"
                    target="__blank"
                  >
                    <AiFillInstagram
                      size="37px"
                      style={{ marginLeft: "11px" }}
                    />
                  </a> */}
                </p>
                <p style={{ paddingTop: "30px" }}>
                  Click to add another person{" "}
                  <Link to="/remembrance/#2">
                    <span>
                      <BsArrowClockwise size="3rem" />
                    </span>
                  </Link>
                </p>
              </Container>
            </div>
          </div>
        </Layout>
      </ClientOnly>
    </div>
  )
}

export default ThankYouPage
